import React from 'react'

function Faq() {
  return (
    <div className='Faq gradientFaq'>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="Faq-header d-flex justify-content-center">
              <h1>Ən çox verilən suallar</h1>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-xl-6">
            <div className="details-box">
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
              <details>
                <summary>
                  “Virtual Hüquqşünas” nədir?
                </summary>
                <p>“Virtual Hüquqşünas” hüquqi suallarınıza cavab verən onlayn platformadır. Burada Azərbaycan qanunvericiliyi ilə bağlı suallar verə, FAQ bölməsində ümumi məlumatlara baxa və vəkillə məsləhətləşmə randevusu ala bilərsiniz.</p>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq